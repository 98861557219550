"use strict";

$(function () {
	//to check if it is the first time the page is loaded or the filters where reset
	var resetLoad = true;
	var id = "marketplace";
	var searchBarEl = $(".search-bar");

	var loader = $(".loader"),
	    ebToken = "",
	    endpoint = $(location).attr('origin'),
	    currentUrlNoParams = window.location.href.split('?')[0],
	    currentStoresLength = 0,
	    standardTake = 20,
	    standardSkip = 0,
	    standardSorting = 2,
	    data = {
		"popularOffers": false,
		"favoritesOnly": false,
		"categories": [],
		"storeName": "",
		"sort": standardSorting,
		"take": standardTake,
		"skip": standardSkip
	},
	    shoppingLinkView = $("#shoppingLink").text(),
	    memberEmail = $("#memberEmail").text(),
	    notEligibleModal = $('#eligibilitymodel'),
	    signupForm = $("#rakuten-signup-modal"),
	    signupModal = $("#mktpModal"),
	    wizardModal = $("#wizard"),
	    errorRakuten = $("#error-rakuten-signup"),
	    shoppingLinkModal = $("#storeLink"),
	    popularOffersText = "Popular Offers",
	    favoritesOnlyText = "My Favorite Stores";

	var user = {
		isHotState: false,
		isRakutenMember: false,
		isWarmColdState: false,
		resultAfterLogin: getParameterByName('result'),
		memberEligibility: $("#memberEligibility").text()
	};

	// Search suggestions ----------------------------------------------------------------------
	var search = {
		searchBox: $("#store-search input"),
		suggestionBox: $("#" + id + " .suggestion-box-mkp"),
		loader: loader,
		seachIcon: $("#" + id + " .search .fa-search"),
		eraseIcon: $("#" + id + " .fa-times.erase"),
		storesFromSearch: [],
		loaderShow: function loaderShow() {
			this.loader.show();
		},
		loaderHide: function loaderHide() {
			this.loader.hide();
		},
		populateBox: function populateBox(data, input) {
			var input = input,
			    elements = "",
			    input = $(input).siblings("#" + id + " .suggestion-box-mkp");
			this.promised = this.promise(data, input);
			input.parent(".search").append(this.loader);
			this.loaderShow();

			this.promised.done(function () {
				if (this.promised.readyState == 4 && this.promised.status == 200) {
					var pageSize = this.promised.responseJSON.Stores.stores.length;
					var dataSuccess = this.promised.responseJSON.Success;
					if (dataSuccess) {
						if (pageSize > 0) {
							this.storesFromSearch = this.promised.responseJSON.Stores.stores;
							$.each(this.storesFromSearch, function (index, el) {
								elements += "<a data-store-guid=\"" + el.StoreGuid + "\" class=\"store-suggestion\">" + el.Name + "</a>";
							});
						}
					}
				}
				this.loaderHide();
				input.html(elements).slideDown('fast');
			}.bind(this));
		},
		hideBox: function hideBox(input) {
			$("#" + id + " .suggestion-box-mkp a").remove();
		},
		promise: function promise() {
			return $.ajax({
				data: data,
				dataType: 'json',
				async: true,
				type: 'POST',
				url: endpoint + "/api/StoreService/Search"
			});
		},
		promised: ""
	};

	// Search results ----------------------------------------------------------------------
	var searchResults = {
		loader: loader,
		sortDropdown: $(".mkp-sorting"),
		cardContainer: $("#" + id + " .cards"),
		seeMore: $("#see-more-stores"),
		favoriteStoresFilterSwitch: $("#store-favorites-only"),
		subcategories: $(".store-subcategories"),
		noResults: $(".store-no-results"),
		noResultsFavorites: $("#favorites-no-result"),
		filterPilsContainer: $("#filterpils"),
		filterPils: $("#filterpils .btn-group"),
		init: function init() {
			if (user.memberEligibility == "Eligible" && user.resultAfterLogin == "1") {
				shoppingLinkModal.text(shoppingLinkView);
				this.openSignupModalWithDelay();
			}

			if (user.memberEligibility == "Eligible" && user.resultAfterLogin == "3") {
				this.openSignupModalWithDelay();
			}
			this.getCards(data);
		},
		clear: function clear() {
			this.cardContainer.html("");
			this.loader.hide();
		},
		getCards: function getCards(data) {
			if (data.take == 0) data.take = standardTake;
			searchResults.noResults.hide();
			searchResults.noResultsFavorites.hide();
			this.loader.insertAfter(this.cardContainer).show();

			this.promised = this.promise(data);
			this.promised.done(function () {
				if (this.promised.responseJSON.Success) {
					var pageSize = this.promised.responseJSON.Stores.stores.length;
					if (pageSize == 0 && resetLoad) {
						resetLoad = false;
						this.promised = this.promise(data);
						this.promised.done(function () {
							if (this.promised.responseJSON.Success) {
								this.populateCardGrid(this.promised.responseJSON);
							}
						}.bind(this));
					} else {
						this.populateCardGrid(this.promised.responseJSON);
					}
				} else {
					this.seeMore.hide();
					searchResults.noResults.show();
				}
			}.bind(this));
		},
		populateCardGrid: function populateCardGrid(result) {
			this.cardContainer.siblings($(".loader").remove());
			var pageSize = result.Stores.stores.length;
			currentStoresLength += pageSize;
			data.take = standardTake;
			data.skip = standardSkip;
			var addCardBanner = false;
			var hasFavoritesFilterOn = false;

			if (this.favoriteStoresFilterSwitch.length > 0) {
				if (this.favoriteStoresFilterSwitch.is(':checked')) {
					hasFavoritesFilterOn = true;
				}
			}

			//add banner only on first page with no filters
			if (data.categories.length <= 0 && currentStoresLength <= 20 && !hasFavoritesFilterOn) {
				addCardBanner = true;
			}

			if (pageSize > 0) {
				user.isHotState = result.Stores.IsHotState;
				user.isWarmColdState = result.Stores.IsWarmColdState;
				user.isRakutenMember = result.Stores.IsRakutenMember;
				this.cardContainer.append(this.populateCards(result.Stores, addCardBanner));
				this.seeMore.show();
			} else {
				if (data.favoritesOnly == true) {
					this.noResultsFavorites.show();
				} else {
					this.noResults.show();
				}
			}

			if (!result.Stores.hasOneMorePage) {
				this.seeMore.hide();
			}
			if (currentStoresLength > 0) {
				this.noResults.hide();
				this.noResultsFavorites.hide();
			}
		},
		populateCards: function populateCards(data, addCardBanner) {
			var cards = data.stores;
			var htmlCards = "";
			var $cardBanner = $(".card-banner");

			$.each(cards, function (index, el) {
				var cardTextVariation = "Can't Miss Deal";
				var offerReward = parseInt(el.TotalReward) - parseInt(el.BaseReward);
				var btnClass = "btn-gray";
				var heart = "";
				var totalRewardText = "";
				var baseRewardText = "&nbsp;";
				var tier = el.Tier;

				if (offerReward >= 0 && offerReward <= 2.99) {
					cardTextVariation = "Everyday Deal";
				} else if (offerReward >= 3 && offerReward <= 4.99) {
					cardTextVariation = "Great Deal";
				}

				if (el.Type == "Percentage") {
					totalRewardText = el.TotalReward + "% Cash&nbsp;Back";
					if (el.BaseReward != el.TotalReward) {
						baseRewardText = "Was " + el.BaseReward + "%";
					}
				} else {
					totalRewardText = "$" + el.TotalReward + " Cash&nbsp;Back";
					if (el.BaseReward != el.TotalReward) {
						baseRewardText = "Was $" + el.BaseReward;
					}
					cardTextVariation = "Everyday Deal";
				}

				if (el.MemberOnly) {
					btnClass = "btn-orange";
					cardTextVariation = "For Members Only";
				}

				if (user.isHotState && user.isRakutenMember) {
					var activeHeart = el.IsFavorite ? " is-active" : "";
					heart = "<div class=\"heart" + activeHeart + "\"></div>";
				}

				totalRewardText = tier ? "Up to " + totalRewardText : totalRewardText;

				if (el.TotalReward == 0) {
					totalRewardText = "&nbsp;";
					baseRewardText = "&nbsp;";
				}

				htmlCards += "<div class=\"card\" data-store-link=\"" + el.ShoppingUrl + "\">\n          <span class=\"hidden\">" + el.Name + "</span>\n          <section class=\"section-sm\">\n            <img class=\"img-fluid\" src=\"" + el.SmallLogo + "\" alt=\"" + el.Name + "\">\n          </section>\n          <section class=\"mid\">\n            <a data-store-guid=\"" + el.StoreGuid + "\"\n            class=\"btn " + btnClass + "\">" + cardTextVariation + "</a>\n            <p class=\"cashback-label p-t-xs " + (tier ? 'tier-padding' : '') + "\">" + totalRewardText + "</p>\n          </section>\n          <section class=\"bottom\">\n            <p><small>" + baseRewardText + "</small></p>\n          </section>\n\t\t  " + heart + "\n        \t\t</div>";

				if (addCardBanner && $cardBanner.length > 0) {
					//add banner 2 columns
					if (index == 1) {
						var htmlCardBanner = $cardBanner.clone().addClass("visible-xs-flex").prop("outerHTML");
						htmlCards += htmlCardBanner;
					}
					//add banner 3 columns
					if (index == 2) {
						var htmlCardBanner = $cardBanner.clone().addClass("visible-sm-flex").prop("outerHTML");
						htmlCards += htmlCardBanner;
					}
					//add banner 4 columns
					if (index == 3) {
						var htmlCardBanner = $cardBanner.clone().addClass("visible-md-flex visible-lg-flex").prop("outerHTML");
						htmlCards += htmlCardBanner;
					}
				}
			});
			return htmlCards;
		},
		promise: function promise(data) {
			return $.ajax({
				data: data,
				dataType: 'json',
				async: true,
				type: 'POST',
				url: endpoint + "/api/StoreService/Search"
			});
		},
		promised: "",
		populateFilterPill: function populateFilterPill(categoryName, categoryGuid) {
			var htmlFilterPil = "<button type=\"button\" class=\"btn btn-default btn-lg\" \n        \t\t\tdata-category-guid=\"" + categoryGuid + "\">\n        \t\t\t" + categoryName + " <span aria-hidden=\"true\">x</span>\n      \t\t\t</button>";

			return htmlFilterPil;
		},
		appendPills: function appendPills(labelText, guid) {
			searchResults.filterPilsContainer.removeClass('hidden');
			this.filterPils.append(this.populateFilterPill(labelText, guid));
		},
		openSignupModalWithDelay: function openSignupModalWithDelay() {
			setTimeout(function () {
				signupModal.modal('show');
			}, 2500);
		}
	};

	// Search results event page ------------------------------------------------------------
	if ($("#marketplace").length > 0) {
		var fireRakutenGTM = function fireRakutenGTM(eventText, storeName, ctaText, href) {
			dataLayerPush({
				'event': eventText,
				'cardTitle': storeName,
				'ctaText': ctaText.trim(),
				'clickHref': href.trim(),
				'productName': "NEA Discount Marketplace"
			}, this);
		};

		var fireSimpleRakutenGTM = function fireSimpleRakutenGTM(clickText, type) {
			var event = "";
			if (type == "checkbox") {
				event = "discount offers checkbox";
			} else if (type == "dropdown") {
				event = "discount offers dropdown";
			}

			dataLayerPush({
				'event': event,
				'filterText': clickText.trim()
			}, this);
		};

		var fireSearchRakutenGTM = function fireSearchRakutenGTM(eventText, storeName) {
			dataLayerPush({
				'event': eventText,
				'searchAction': "search entry",
				'searchText': storeName.trim()
			}, this);
		};

		var resetRequestWithParameters = function resetRequestWithParameters(searchTerm, take, skip, sortType, resetCategories) {
			var favoritesOnly = false;
			if (searchResults.favoriteStoresFilterSwitch.length > 0) {
				favoritesOnly = searchResults.favoriteStoresFilterSwitch.is(":checked");
			}

			if (resetCategories) {
				data.categories = [];
			}
			//data.popularOffers = popularOffers;
			data.favoritesOnly = favoritesOnly;
			data.storeName = searchTerm;
			currentStoresLength = 0;
			data.take = take;
			data.skip = skip;
			data.sort = sortType;
		};

		var resetAllFiltersView = function resetAllFiltersView(hardReset) {
			$(".parent-filter").removeClass('open');
			$(".childs").removeClass('childSelected').find('.form-group').fadeOut();
			$(".more").remove();
			$('.parent-filter p').removeClass('open');
			searchResults.filterPilsContainer.addClass('hidden');
			searchResults.filterPils.html("");
			searchResults.noResults.hide();
			searchResults.noResultsFavorites.hide();

			$("input:checkbox").prop('checked', false);
		};

		var resetStoreGridAndFinderView = function resetStoreGridAndFinderView(searchTerm) {
			if (!searchTerm) searchTerm = "";
			search.eraseIcon.hide();
			search.searchBox.val(searchTerm);
			search.suggestionBox.slideUp();
			searchResults.clear();
		};

		var resetPillsContainer = function resetPillsContainer() {
			if (searchResults.filterPilsContainer.find('div.col-md-12 button').length == 0) {
				searchResults.filterPilsContainer.addClass('hidden');
			}
		};

		var removeGuidCategory = function removeGuidCategory(categoryGuid) {
			return data.categories.filter(function (item) {
				return item !== categoryGuid;
			});
		};

		var filterEvents = function filterEvents(context) {
			var parent = $(context).closest('.parent-filter'),
			    accBtn = parent.find('.accordion-btn'),
			    childs = parent.find(".childs"),
			    child = $(childs).find("input[type='checkbox']"),
			    input = childs.find('input:checkbox:checked'),
			    inputWrapper = childs.find('input:checkbox:not(":checked")').closest('.form-group');

			if (input.length > 0 && !childs.hasClass('childSelected')) {
				inputWrapper.fadeOut(function () {
					childs.addClass('childSelected');
					if (childs.find("p.more").length === 0) {
						childs.append('<p class="more">More</p>');
					}
				});
			} else if (input.length > 0 && childs.hasClass('childSelected')) {
				inputWrapper.fadeIn();
				childs.removeClass('childSelected');
				childs.find(".more").remove();
			}

			//----------------------------------------------------
			if (input.length === 0 && !accBtn.hasClass('open')) {
				inputWrapper.fadeToggle();
				accBtn.toggleClass('open');
				child.first().trigger("click");
			} else if (input.length === 0 && accBtn.hasClass('open') && childs.hasClass('childSelected')) {
				inputWrapper.fadeIn();
				childs.find(".more").remove();
				childs.removeClass('childSelected');
			} else if (input.length === 0 && accBtn.hasClass('open')) {
				inputWrapper.fadeToggle();
				accBtn.toggleClass('open');
			}
		};

		// Searchbox events ----------------------------------------------------  


		//Method to check if url has parameter to search for cards
		var currentUrlParameters = new URLSearchParams(document.location.search);
		var searchBox = $("#store-search input");
		var searchParam = currentUrlParameters.get('store-search');
		if (currentUrlParameters.has('store-search')) {
			var data = {
				"popularOffers": false,
				"favoritesOnly": false,
				"categories": [],
				"storeName": searchParam,
				"sort": 2,
				"take": 20,
				"skip": 0
			};
			searchBox.val(searchParam);
			searchResults.clear();
			searchResults.getCards(data);
			$(window).on("load", function (e) {
				var $target = $('#marketplace');
				e.preventDefault();
				$('html, body').animate({
					scrollTop: $target.offset().top
				}, 1000);
				$target.on('focus');
			});
		} else {
			searchResults.init();
		}

		searchResults.sortDropdown.on("change", function (e) {
			data.sort = this.value;
			data.take = currentStoresLength;
			if (searchBox.val().trim().length === 0) {
				data.storeName = "";
			}
			currentStoresLength = 0;
			searchResults.clear();
			search.eraseIcon.hide();
			var sortText = $(this).find("option:selected").text();
			var sortVal = $(this).find("option:selected").val();
			searchResults.sortDropdown.each(function (index, el) {
				$(el).val(sortVal);
			});

			searchResults.getCards(data);
		});

		searchResults.favoriteStoresFilterSwitch.on("change", function (e) {
			var sort = searchResults.sortDropdown.val();

			if ($(this).is(':checked')) {
				searchResults.appendPills(favoritesOnlyText, favoritesOnlyText);
				fireSimpleRakutenGTM(favoritesOnlyText, "checkbox");
			} else {
				$("button[data-category-guid='" + favoritesOnlyText + "']").remove();
			}

			resetRequestWithParameters("", standardTake, standardSkip, sort, false);
			resetStoreGridAndFinderView();
			resetPillsContainer();
			searchResults.getCards(data);
		});

		searchResults.subcategories.on("click", function (e) {
			var categoryChecked = $(this).val();
			var categoryText = $(this).parent().siblings().text();
			var sort = searchResults.sortDropdown.val();
			resetLoad = false;

			if ($(this).is(':checked')) {
				if ($(this).closest("div.form-group").hasClass("child-category")) {
					var parentCategory = $(this).parents(".childs").find(".all-category input");
					if ($(parentCategory).is(':checked')) {
						parentCategory.prop('checked', false);
						$("button[data-category-guid='" + parentCategory.val() + "']").remove();
						data.categories = removeGuidCategory(parentCategory.val());
					}
				}
				data.categories.push(categoryChecked);
				searchResults.appendPills(categoryText, categoryChecked);
				fireSimpleRakutenGTM(categoryText, "checkbox");
			} else {
				data.categories = removeGuidCategory(categoryChecked);
				$("button[data-category-guid='" + categoryChecked + "']").remove();
			}
			resetRequestWithParameters("", standardTake, standardSkip, sort, false);
			resetStoreGridAndFinderView();
			if (searchResults.promised) searchResults.promised.abort();
			searchResults.getCards(data);
		});

		searchResults.seeMore.on("click", function (e) {
			e.preventDefault();
			data.skip = currentStoresLength;
			searchResults.getCards(data);
		});

		$(document).on('click', '#marketplace .card, #exclusive-offers .card, .heart', function (e) {
			e.stopPropagation();
			var $that = $(this);
			if ($that.hasClass('heart')) {
				var storeGuid = $that.closest('.card').find('a').data("store-guid"),
				    behaviour = $that.hasClass('is-active');
				$.ajax({
					data: {
						store: storeGuid,
						behaviour: !behaviour
					},
					dataType: 'json',
					async: true,
					type: 'POST',
					url: endpoint + '/api/StoreService/FavoriteStore',
					success: function success(response) {
						if (response.Success) {
							$that.toggleClass("is-active");
						} else {
							toastr.error('Favorite store could not be saved, please try later.');
						}
					},
					error: function error(_error) {
						toastr.error('Server error');
					}
				});
			} else {
				var storeGuid = $(this).find("a").data("store-guid");
				var link = $(this).data("store-link");
				var storeLabel = $(this).find("a").text();
				var cashbackLabel = $(this).find(".cashback-label").text();
				var storeName = $(this).find("span").text();
				var ctaText = storeLabel + " " + cashbackLabel;
				var encodedCta = encodeURI(ctaText.trim());
				var GTMLink = currentUrlNoParams + " | Rakuten Register";
				var eventText = "discount offer card";
				var hotDealParam = "";

				if ($(this).hasClass("hot-deal")) {
					eventText = "trending discounts";
					hotDealParam = "&hotdeal=1";
				}

				fireTrackingOracleSP();
				if (user.isHotState) {
					if (user.memberEligibility == "NotEligible" || user.memberEligibility == "None") {
						notEligibleModal.modal('show');
					} else {
						if (user.resultAfterLogin !== "") {
							if (user.resultAfterLogin == "1") {
								fireRakutenGTM(eventText, storeName, ctaText, GTMLink);
								shoppingLinkModal.text(shoppingLinkView);
								signupModal.modal('show');
							} else if (user.resultAfterLogin == "3") {
								signupModal.modal('show');
							} else {
								notEligibleModal.modal('show');
							}
						} else {
							if (user.isRakutenMember) {
								fireRakutenGTM(eventText, storeName, ctaText, link);
								window.open(link, '_blank');
							} else {
								fireRakutenGTM(eventText, storeName, ctaText, GTMLink);
								shoppingLinkModal.text(link);
								signupModal.modal('show');
							}
						}
					}
				} else {
					if (user.memberEligibility == "NotEligible") {
						notEligibleModal.modal('show');
					} else {
						if (user.isWarmColdState) {
							fireRakutenGTM(eventText, storeName, ctaText, endpoint + "/account/zip-code-verification");
							window.location.href = endpoint + "/account/zip-code-verification";
						} else {
							fireRakutenGTM(eventText, storeName, ctaText, endpoint + "/login");
							window.location.href = endpoint + "/login?store=" + storeGuid + "&ctaText=" + encodedCta + hotDealParam;
						}
					}
				}
			}
		});

		signupForm.submit(function (e) {
			e.preventDefault();
			var url = endpoint + "/api/RakutenRoute/SignUp";

			dataLayerPush({
				'event': 'cash back email sign up',
				'ctaText': 'I Agree'
			}, this);

			$.ajax({
				type: "GET",
				url: url,
				async: false,
				data: {
					isneamb: true
				},
				complete: function complete(data) {
					if (data.responseJSON.result == "ok") {
						ebToken = data.responseJSON.ebtoken;
						signupModal.modal("hide");
						wizardModal.modal("show");
					} else {
						errorRakuten.removeClass("hidden");
					}
				}
			});
		});

		signupModal.on('hidden.bs.modal', function () {
			errorRakuten.addClass("hidden");
			$("#rakuten-signup-modal input[type='email']").val("");
		});

		signupModal.on('show.bs.modal', function () {
			$("#mktpModal").removeClass("modal-slick-hidden");
		});

		signupModal.on('shown.bs.modal', function () {
			$("#rakuten-signup-modal input[type='email']").val(memberEmail);
		});

		$(document).on('click', '#wizard .close-wizard', function (e) {
			e.preventDefault();
			if (user.resultAfterLogin != "3") {
				var redirectToStoreUrl = shoppingLinkModal.text();
				redirectToStoreUrl += ebToken;
				window.location = currentUrlNoParams;
				window.open(redirectToStoreUrl, '_blank').focus();
			}
			window.location = currentUrlNoParams;
		});

		$(document).on('click', 'p.more , .parent-filter > p', function (e) {
			e.stopPropagation();
			filterEvents(this);
		});

		$(document).on('click', '#reset, #mobile-reset', function () {
			searchResults.sortDropdown.val(standardSorting);
			resetAllFiltersView(true);
			resetStoreGridAndFinderView();
			resetRequestWithParameters("", standardTake, standardSkip, standardSorting, true);
			resetLoad = true;
			searchResults.getCards(data);
		});

		$(document).on('click', '#filterMBtn, #submit, #mobile-reset, .filter-label .close', function (e) {
			e.preventDefault();
			this.blur();
			window.focus();
			$("#filters").toggleClass('show');
			if ($("#filters").hasClass('show')) {
				document.body.style.overflow = 'hidden';
			} else {
				document.body.style.overflow = '';
			}
		});

		$(document).on('click', '#filterpils span', function () {
			var categoryGuid = $(this).parent().data("category-guid");
			var sort = searchResults.sortDropdown.val();
			if (categoryGuid == popularOffersText) {} else if (categoryGuid == favoritesOnlyText) {
				searchResults.favoriteStoresFilterSwitch.prop('checked', false);
			} else {
				data.categories = removeGuidCategory(categoryGuid);
			}

			resetRequestWithParameters("", standardTake, standardSkip, sort, false);
			resetStoreGridAndFinderView();
			searchResults.getCards(data);

			$('input[value="' + categoryGuid + '"]').prop('checked', false);
			$(this).parent().remove();

			resetPillsContainer();
		});

		$(document).on('click', '#exclusive-offers .slick-next', function (e) {
			dataLayerPush({
				'event': 'trending discounts arrow',
				'Click Element': 'right arrow'
			}, e.target);
		});

		$(document).on('click', '#exclusive-offers .slick-prev', function (e) {
			dataLayerPush({
				'event': 'trending discounts arrow',
				'Click Element': 'left arrow'
			}, e.target);
		});

		search.searchBox.on("keyup", function (e) {
			var input = $(this);

			if (input.val().length > 2 && e.which != 13) {
				$(this).closest('.search').find('.fa.fa-times.erase').show();
				$(this).closest('.search').addClass('erase-show');
				resetAllFiltersView();
				resetRequestWithParameters(input.val(), 5, 0, 0, true);
				if (search.promised) search.promised.abort();
				search.populateBox(data, input);
			}
			if (e.key === "Enter") {
				data.take = 20;
				data.storeName = input.val();
				data.sort = searchResults.sortDropdown.val();
				data.skip = 0;
				search.hideBox(input);
				searchResults.clear();
				searchResults.getCards(data);
			}
			if (input.val().length == 0) {
				$(this).closest('.search').find('.fa.fa-times.erase').hide();
				$(this).closest('.search').removeClass('erase-show');
			}
		}).on("blur", function () {
			if (search.promised) search.promised.abort();
			search.loaderHide();
		});

		search.seachIcon.on("click", function () {
			if ($(this).siblings('input').val().length > 2) {}
		});

		search.eraseIcon.on("click", function () {
			search.promised.abort();
			search.loaderHide();
			searchResults.sortDropdown.val(standardSorting);

			$(this).closest('.search').find('input').val("");
			$(this).closest('.search').removeClass('erase-show');
			$(this).hide();

			resetAllFiltersView(true);
			resetStoreGridAndFinderView();
			resetRequestWithParameters("", standardTake, standardSkip, standardSorting, true);
			resetLoad = true;
			searchResults.getCards(data);

			setTimeout(function () {
				search.promised.abort();
				search.suggestionBox.hide();
			}, 350);
		});

		$(document).on("click", "#marketplace .suggestion-box-mkp a", function (e) {
			search.searchBox.val($(this).text());

			var storeGuid = $(this).attr("data-store-guid");
			searchResults.clear();
			search.suggestionBox.slideUp();

			var storeSelected = findObjectByProperty(search.storesFromSearch, "StoreGuid", storeGuid);
			var dataLocal = {
				"isHotStates": user.isHotState,
				"isRakutenMember": user.isRakutenMember,
				"stores": []
			};

			dataLocal.stores.push(storeSelected);
			resetAllFiltersView();
			fireSearchRakutenGTM("discount search", storeSelected.Name);
			searchResults.cardContainer.append(searchResults.populateCards(dataLocal));
			searchResults.seeMore.hide();

			if (searchBarEl.hasClass("is-pinned")) {
				customScrollToByClass("cards-container");
			}
		});

		//sticky marketplace search bar
		var targetTop = document.querySelector(".top-marker");
		var targetBottom = document.querySelector(".bottom-marker");
		var isTopVisible = false;
		var isBottomVisible = false;

		var onIntersect = function onIntersect(entries) {
			entries.forEach(function (entry) {
				if (entry.target == targetTop) {
					isTopVisible = entry.isIntersecting;
				}

				if (entry.target == targetBottom) {
					isBottomVisible = entry.isIntersecting;
				}
			});
			if (isBottomVisible && !isTopVisible) {
				searchBarEl.addClass("is-pinned");
			} else {
				searchBarEl.removeClass("is-pinned");
			}
		};

		var observer = new IntersectionObserver(onIntersect, {
			threshold: [0]
		});

		observer.observe(targetTop);
		observer.observe(targetBottom);
	}
});

function findObjectByProperty(array, propertyName, propertyValue) {
	return array.find(function (o) {
		return o[propertyName] === propertyValue;
	});
}

// $(function() {
// 	document.getElementById('displayed_cards').scrollIntoView().delay(500);
// });