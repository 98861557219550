'use strict';

$(function () {
	var endpoint = $(location).attr('origin');

	setupFormInfoMesages();
	setupTextAreaLabelHelper();
	setupHiddingModalAction();

	$('input#phone').mask('000 000 0000');
	$('input#zip').mask('00000');
	$('input#payoutPercentage').mask('000');
	$(".full-date #month, .full-date #day").mask('00');
	$(".full-date #year").mask('0000');

	//Show password with eye click
	$(".showPassword").on("click", function () {
		var $passInput = $(this).siblings("input");
		if ($passInput.prop("type") === "password") {
			$passInput.prop("type", "text");
			$(this).find(".eye-icon").addClass("open");
		} else {
			$passInput.prop("type", "password");
			$(this).find(".eye-icon").removeClass("open");
		}
	});

	if ($('.error-bar').length > 0) {
		$('.error-bar i').on('click', function () {
			$('.error-bar').removeClass('show');
		});
	}

	//if warm data exists
	if ($('.select-warm').length > 0) {
		$.each($('.select-warm'), function () {
			var warm = $(this).text().trim(),
			    id = $(this).data('id'),
			    select = $(this).siblings('.custom-select');
			if (warm != '') {
				$('#' + id).val(warm);
				select.find('.custom-select__option--value').text(warm);
			}
		});
	}

	//FORMS EVENTS -------------------------------------------------------------------------------------
	if ($('form#contact-us').length > 0) {
		var validateTelephone = function validateTelephone(object, event) {
			if (event.keyCode > 31 && (event.keyCode < 48 || event.keyCode > 57) && (event.keyCode < 96 || event.keyCode > 105)) {
				event.preventDefault();
			}
			if (object.value.length == 3 && event.keyCode != 8) object.value = object.value + " ";
			if (object.value.length == 7 && event.keyCode != 8) object.value = object.value + " ";
			if (object.value.length == 12 && event.keyCode != 8) event.preventDefault();
		};

		isUnmachedMember();
		validateMessageCharCount();
		var $contactForm = $('form#contact-us');

		$contactForm.validate({
			onfocusout: function onfocusout(element) {
				this.element(element);
				setupAccentWhenValidForm("contact-us", 6);
			},
			ignore: ".ignore, :hidden",
			rules: {
				email: {
					isValidEmail: true
				}
			},
			errorElement: "div",
			errorPlacement: function errorPlacement(error, element) {
				setupErrorPlacementValidation(error, element);
			},
			submitHandler: function submitHandler(form) {
				$('button[type="submit"]').addClass('disabled');
				$($contactForm).find(".fa-spinner").show();
				form.submit();
			}
		});

		$('form#contact-us #phone').on('keyup', function (event) {
			var size = $(this).val().length;
			console.log(size);
			validateTelephone(this, event);
		});
	}
	if ($('form#forgot-password').length > 0) {
		var $forgotPasswordForm = $('form#forgot-password');

		$forgotPasswordForm.validate({
			onfocusout: function onfocusout(element) {
				this.element(element);
				setupAccentWhenValidForm("forgot-password", 1);
			},
			rules: {
				email: {
					isValidEmail: true
				}
			},
			errorElement: "div",
			errorPlacement: function errorPlacement(error, element) {
				setupErrorPlacementValidation(error, element);
			},
			submitHandler: function submitHandler(form) {
				$($forgotPasswordForm).find(".fa-spinner").show();
				form.submit();
				$("button[type=submit]").addClass("disabled");
			}
		});
	}

	if ($('form#forgot-email').length > 0) {
		var $forgotEmailForm = $('form#forgot-email');

		$forgotEmailForm.validate({
			onfocusout: function onfocusout(element) {
				this.element(element);
				setupAccentWhenValidForm("forgot-email", 6);
			},
			ignore: ".ignore, :hidden",
			rules: {
				year: {
					isValidDate: true
				}
			},
			groups: {
				birthDate: "month day year"
			},
			errorElement: "div",
			errorPlacement: function errorPlacement(error, element) {
				setupErrorPlacementValidation(error, element);
			},
			submitHandler: function submitHandler(form) {
				$($forgotEmailForm).find(".fa-spinner").show();
				form.submit();
			}
		});
	}

	if ($('form#reset-password').length > 0) {
		var $resetPasswordForm = $('form#reset-password');

		$resetPasswordForm.validate({
			onfocusout: function onfocusout(element) {
				this.element(element);
				setupAccentWhenValidForm("reset-password", 2);
			},
			errorElement: "div",
			errorPlacement: function errorPlacement(error, element) {
				setupErrorPlacementValidation(error, element);
			},
			submitHandler: function submitHandler(form) {
				$($resetPasswordForm).find(".fa-spinner").show();
				form.submit();
			}
		});
	}
	if ($('form#pearl-recurring').length > 0) {
		validateMessageCharCount();
		var $pearlForm = $('form#pearl-recurring');

		$pearlForm.validate({
			onfocusout: function onfocusout(element) {
				this.element(element);
				setupAccentWhenValidForm("pearl-recurring", 1);
			},
			ignore: ".ignore, :hidden",
			errorElement: "div",
			errorPlacement: function errorPlacement(error, element) {
				setupErrorPlacementValidation(error, element);
			},
			submitHandler: function submitHandler(form) {
				$($pearlForm).find(".fa-spinner").show();
				form.submit();
			}
		});
	}

	if ($('form#areaForm').length > 0) {
		var $resetPasswordForm = $('form#areaForm');

		$resetPasswordForm.validate({
			onfocusout: function onfocusout(element) {
				this.element(element);
				setupAccentWhenValidForm("areaForm", 2);
			},
			errorElement: "div",
			errorPlacement: function errorPlacement(error, element) {
				setupErrorPlacementValidation(error, element);
			},
			submitHandler: function submitHandler(form) {
				$($resetPasswordForm).find(".fa-spinner").show();
				form.submit();
			}
		});
	}

	if ($('form#update-profile').length > 0) {
		setupAccentWhenValidForm("update-profile", 10);

		var msg = "Closed items cannot be linked to items of type ";
		var messager = function messager() {
			return msg;
		};

		var $updateProfileForm = $('form#update-profile');
		$updateProfileForm.validate({
			onfocusout: function onfocusout(element) {
				this.element(element);
				setupAccentWhenValidForm("update-profile", 10);
			},
			rules: {
				year: {
					isValidDate: true
				},
				email: {
					isValidEmail: true,
					remote: {
						url: "/api/EmailValidation/EmailValidationRules",
						type: "GET",
						data: {
							email: function email() {
								return $("#email").val();
							},
							validateUsername: true,
							invalidMailError: $('#email').data('msg-email'),
							registeredUserError: $('#email').data('emailinuse'),
							reservedDomainError: $('#email').data('msg-hasreserveddomain')
						}
					}
				}
			},
			ignore: ".ignore, :hidden",
			groups: {
				birthDate: "month day year"
			},
			errorElement: "div",
			errorPlacement: function errorPlacement(error, element) {
				setupErrorPlacementValidation(error, element);
			},
			submitHandler: function submitHandler(form) {
				$($updateProfileForm).find(".btn .fa-spinner").show();
				form.submit();
			}
		});
	}

	if ($('form#update-password').length > 0) {
		var $updatePasswordForm = $('form#update-password');
		$updatePasswordForm.validate({
			onfocusout: function onfocusout(element) {
				this.element(element);
				setupAccentWhenValidForm("update-password", 3);
			},
			errorElement: "div",
			errorPlacement: function errorPlacement(error, element) {
				setupErrorPlacementValidation(error, element);
			},
			submitHandler: function submitHandler(form) {
				$($updatePasswordForm).find(".fa-spinner").show();
				form.submit();
			}
		});
	}

	if ($('form#zip-verification').length > 0) {
		$zipVerificationForm = $('form#zip-verification');
		$zipVerificationForm.validate({
			onkeyup: function onkeyup(element) {
				this.element(element); // <- "eager validation"
				setupAccentWhenValidForm("zip-verification", 1);
			},
			errorElement: "div",
			errorPlacement: function errorPlacement(error, element) {
				setupErrorPlacementValidation(error, element);
			},
			submitHandler: function submitHandler(form) {
				$zipVerificationForm.find(".fa-spinner").show();
				form.submit();
			}
		});
	}
	// Beneficiary section

	if ($('section.new-beneficiary').length > 0) {
		var $progressbar = $('div.progress-bar');
		var inputValue = $('#payoutPercentage').val() ? $('#payoutPercentage').val() : 0;
		var progressbarvalue = parseInt($progressbar.attr('aria-valuenow'));

		var firstTime = false;
		var firstPercentage = parseInt(progressbarvalue);
		var inputPercentage = isNaN(parseInt($('#payoutPercentage').val())) ? 0 : parseInt($('#payoutPercentage').val());
		var initialPayout = firstPercentage - (isNaN(inputPercentage) ? 0 : inputPercentage);

		// Set progress bar percentaje
		var $progressbarvalue = ProgressBarMax();
		$progressbar.css('width', $progressbarvalue + '%');
		$progressbar.text($progressbarvalue + '%');
		$progressbar.attr('aria-valuenow', $progressbarvalue);

		alertColor();

		//List validations only
		if ($('div.accordion-item').length == 6) {
			$("a#add-new-beneficiary").addClass("disabled");
		} else {
			$("a#add-new-beneficiary").removeClass("disabled");
		}

		if ($progressbarvalue == 100 || $progressbarvalue == 0) {
			$("button[id=save-beneficiaries]").removeClass("disabled");
		} else {
			$("button[id=save-beneficiaries]").addClass("disabled");
		}
	}

	function alertColor() {
		if ($progressbar.attr('aria-valuenow') == 100) {
			$("span.badge").addClass("text-bg-success ");
			if ($('div[role=alert].alert')) {
				$('div[class=card-body] div[role=alert].alert').addClass("alert-success");
				$('div.progress').addClass("border-success");
				$progressbar.addClass("bg-success");
				$('div[class=card-body] div[role=alert].alert').removeClass("alert-danger");
				$('div.progress').removeClass("border-danger");
				$progressbar.removeClass("bg-danger");
				$('div[class=card-body] div[role=alert].alert').removeClass("alert-secondary");
				$('div.progress').removeClass("border-secondary");
				$progressbar.removeClass("bg-secondary");
			}
		} else {
			if ($progressbar.attr('aria-valuenow') < 100) {
				$("span.badge").addClass("text-bg-secondary ");
				if ($('div[role=alert].alert')) {
					$('div[class=card-body] div[role=alert].alert').addClass("alert-secondary");
					$('div.progress').addClass("border-secondary");
					$progressbar.addClass("bg-secondary");
					$('div[class=card-body] div[role=alert].alert').removeClass("alert-danger");
					$('div.progress').removeClass("border-danger");
					$progressbar.removeClass("bg-danger");
					$('div[class=card-body] div[role=alert].alert').removeClass("alert-success");
					$('div.progress').removeClass("border-success");
					$progressbar.removeClass("bg-success");
				}
			} else {
				$("span.badge").addClass("text-bg-danger");
				if ($('div[role=alert].alert')) {
					$('div[class=card-body] div[role=alert].alert').addClass("alert-danger");
					$('div.progress').addClass("border-danger");
					$progressbar.addClass("bg-danger");
					$('div[class=card-body] div[role=alert].alert').removeClass("alert-secondary");
					$('div.progress').removeClass("border-secondary");
					$progressbar.removeClass("bg-secondary");
					$('div[class=card-body] div[role=alert].alert').removeClass("alert-success");
					$('div.progress').removeClass("border-success");
					$progressbar.removeClass("bg-success");
				}
			}
		}
	}

	function ProgressBarMax() {
		var leftPercentage = 100 - progressbarvalue;
		var currentPayout = $('#currentPayout');
		var payoutAvailable = $('#payoutAvailable');

		var allocated = $('.alert li:first');
		var available = $('.alert li:nth-child(2)');

		if (leftPercentage >= 0) {
			$("button[type=submit]").removeClass("disabled");
			if (!firstTime) {
				firstTime = true;
				var availablePercentage = progressbarvalue;
				allocated.text('Allocated = ' + availablePercentage + '%');
				available.text('Available = ' + (100 - availablePercentage) + '%');
				currentPayout.attr('value', availablePercentage);
				payoutAvailable.attr('value', 100 - availablePercentage);
				return availablePercentage;
			} else {
				var payoutValue = parseInt($('#payoutPercentage').val());
				var availablePercentage = initialPayout + (isNaN(payoutValue) ? 0 : payoutValue);
				if (availablePercentage <= 100) {
					allocated.text('Allocated = ' + availablePercentage + '%');
					available.text('Available = ' + (100 - availablePercentage) + '%');
					currentPayout.attr('value', availablePercentage);
					payoutAvailable.attr('value', 100 - availablePercentage);
					return parseInt(availablePercentage);
				} else {
					allocated.text('Allocated = ' + availablePercentage + '%');
					available.text('Available = 0%');
					currentPayout.attr('value', availablePercentage);
					payoutAvailable.attr('value', 100 - availablePercentage);
					return parseInt(availablePercentage);
				}
			}
		} else {
			var allocatedSum = parseInt(progressbarvalue);
			var payoutValue = parseInt($('#payoutPercentage').val());
			var availablePercentage = initialPayout + (isNaN(payoutValue) ? 0 : payoutValue);
			allocated.text('Allocated = ' + availablePercentage + '%');
			available.text('Available = ' + (100 - availablePercentage < 0 ? 0 : 100 - availablePercentage) + '%');
			return parseInt(availablePercentage);
		}
	}
	if ($('form#add-beneficiary').length > 0) {
		var $addBeneficiaryForm = $('form#add-beneficiary'),
		    radios = $("form#add-beneficiary input[type='radio']");

		//Dinamically update progress bar
		$('#payoutPercentage').on('keyup change', function () {

			var $totalProgress = ProgressBarMax();
			$progressbar.css('width', $totalProgress + '%');
			$progressbar.text($totalProgress + '%');
			$progressbar.attr('aria-valuenow', $totalProgress);
			alertColor();
		});

		$.each(radios, function () {
			if ($(this).is(':checked')) {
				$('.group').show().not('.' + $(this).attr('id')).hide();
				// $('.group').not(`.${$(this).attr('id')}`).attr('type','hidden');
				var notSelected = $('.group').not('.' + $(this).attr('id'));
				notSelected.find('input').addClass('ignore');
				notSelected.find('input').css("display", "none");
				notSelected.find('input').attr("required", false);
			}
		});

		radios.on("change", function () {
			radios.not(this).prop('checked', false);
			$('.group').show().not('.' + $(this).attr('id')).hide();
			var selectedRadio = $(this).attr('id');
			// $('.group.'+selectedRadio).removeAttr('type');
			var notSelected = $('.group').not('.' + $(this).attr('id'));
			notSelected.find('input').addClass('ignore');
			notSelected.find('select').addClass('ignore');
			notSelected.find('input').css("display", "none");
			notSelected.find('select').attr("display", "none");
			notSelected.find('input').attr("required", false);
			notSelected.find('select').attr("required", false);
			radios.not(this).attr("checked", false);
			var Selected = $('.group.' + selectedRadio);
			Selected.find('input').removeClass('ignore');
			Selected.find('select').removeClass('ignore');
			Selected.find('select').css("display", "block");
			Selected.find('select').attr("required", true);
			Selected.find('input').css("display", "block");
			Selected.find('input').attr("required", true);
			$(this).attr("checked", true);
			$('#middleInitial').attr("required", false);
		});

		setupPayoutLeftMessage();
		var validInputsCount = 6;
		$addBeneficiaryForm.validate({
			ignore: '.ignore',
			onfocusout: function onfocusout(element) {
				this.element(element);
				var radioChecked = $("form#add-beneficiary input[type='radio']:checked").val();
				if (radioChecked !== "NamedIndividual") {
					validInputsCount = 3;
				}
				setupAccentWhenValidForm("add-beneficiary", validInputsCount);
			},
			rules: {
				payoutPercentage: {
					min: 1,
					max: 100
				},
				email: {
					isValidEmail: true
				}
			},
			submitHandler: function submitHandler(form) {
				$addBeneficiaryForm.find(".fa-spinner").show();
				form.submit();
				$("button[type=submit]").addClass("disabled");
			},
			errorElement: "div",
			errorPlacement: function errorPlacement(error, element) {
				setupErrorPlacementValidation(error, element);
			}
		});
	}

	if ($('#more-about-you-form').length > 0) {
		var $formSelects = $('#more-about-you-form select'),
		    $formSubmits = $('#moreAboutYou .submit_comp_life'),
		    $moreAboutYouForm = $('#more-about-you-form');

		$formSelects.on("change", function () {
			if ($('#percentOK').val() > 0) {
				$formSubmits.removeClass('disabled').removeAttr('disabled');
			} else {
				$formSubmits.addClass('disabled').attr('disabled', 'disabled');
			}
		});

		$moreAboutYouForm.validate({
			onfocusout: function onfocusout(element) {
				var submitBtn = $("#more-about-you-form button[type=submit]");
				if ($moreAboutYouForm.valid()) {
					submitBtn.addClass("accent");
				} else {
					submitBtn.removeClass("accent");
				}
			},
			errorElement: "div",
			errorPlacement: function errorPlacement(error, element) {
				setupErrorPlacementValidation(error, element);
			},
			submitHandler: function submitHandler(form) {
				$moreAboutYouForm.find(".fa-spinner").show();
				form.submit();
			}
		});
	}

	//children birth date year
	if ($('#CBY').length > 0) {
		var $childrenBirthSelect = $('.childYear');
		var $childrenCountSelect = $('#NumberOfChildren_SelectedValue');
		var chldsMngr = {
			hideAll: function hideAll() {
				$childrenBirthSelect.each(function (index, el) {
					$(el).parents(".cby-group").hide();
				});
			},
			addRemoveSelectForChildren: function addRemoveSelectForChildren(el) {
				var repeater = $(el).val();
				this.hideAll();
				for (var i = 0; i <= repeater - 1; i++) {
					$childrenBirthSelect.eq(i).parents(".cby-group").show();
				}
			}
		};

		chldsMngr.addRemoveSelectForChildren($childrenCountSelect);
		$childrenCountSelect.on('change', function () {
			chldsMngr.addRemoveSelectForChildren(this);
		});
	}

	if ($('form#field-rep').length > 0) {
		$fieldRepForm = $("form#field-rep");
		$fieldRepForm.validate({
			onclick: function onclick(element) {
				this.element(element);
				setupAccentWhenValidForm("field-rep", 1);
			},
			errorElement: "div",
			errorPlacement: function errorPlacement(error, element) {
				setupErrorPlacementValidation(error, element);
			},
			submitHandler: function submitHandler(form) {
				$fieldRepForm.find(".fa-spinner").show();
				form.submit();
			}
		});

		//remember state representative form
		var context = $("form#field-rep select#state");
		context.on("change", function () {
			localStorage.setItem("state_text", context.find(':selected').val());
		});
		if (localStorage.getItem("state_text") != "") {
			$.each($("form#field-rep select#state option"), function (index, el) {
				var valueSelected = localStorage.getItem("state_text");
				if ($(el).val() == valueSelected) {
					$(el).attr("selected", "selected");
					context.val($(el).val()).trigger("change");
					context.addClass("valid");
					setupAccentWhenValidForm("field-rep", 1);
				}
			});
		} else {
			context.removeClass("valid");
		}
	}

	if ($('form#member-welcome').length > 0) {
		$memberWelcomeForm = $("form#member-welcome");
		$submitButton = $('#member-welcome :submit');
		$memberWelcomeForm.validate({
			onkeyup: function onkeyup(element) {
				this.element(element);
				setupAccentWhenValidForm("member-welcome", 1);
			},
			errorElement: "div",
			errorPlacement: function errorPlacement(error, element) {
				setupErrorPlacementValidation(error, element);
			},
			submitHandler: function submitHandler(form) {
				$memberWelcomeForm.find(".fa-spinner").show();
				form.submit();
			}
		});
		$memberWelcomeForm.find("input").on('blur keyup', function () {
			$memberWelcomeForm.valid() ? $submitButton.removeClass('disabled').attr('disabled', false) : $submitButton.addClass('disabled').attr('disabled', 'disabled');
		});
	}

	if ($('form#member-verification').length > 0) {
		$memberVerificationForm = $("form#member-verification");
		$($memberVerificationForm).validate({
			onfocusout: function onfocusout(element) {
				this.element(element);
				setupAccentWhenValidForm("member-verification", 1);
			},
			ignore: ".ignore, :hidden",
			errorElement: "div",
			errorPlacement: function errorPlacement(error, element) {
				setupErrorPlacementValidation(error, element);
			},
			submitHandler: function submitHandler(form) {
				$memberVerificationForm.find(".fa-spinner").show();
				form.submit();
			}
		});
	}

	if ($('form#selectOneRegistration').length > 0) {
		var $duplicateRegistrationStepOne = $('form#selectOneRegistration');

		$duplicateRegistrationStepOne.validate({
			onfocusout: function onfocusout(element) {
				this.element(element);
			},
			errorElement: "div",
			errorPlacement: function errorPlacement(error, element) {
				if (element[0].name == "selectedid") {
					error.appendTo($("#errorRadioRequired .floating-error-messages"));
				} else {
					setupErrorPlacementValidation(error, element);
				}
			},
			submitHandler: function submitHandler(form) {
				$($duplicateRegistrationStepOne).find(".fa-spinner").show();
				checkPassword();
			}
		});
	}

	if ($('form#cta').length > 0) {
		$newsletterForm = $("form#cta");
		$newsletterForm.validate({
			onkeyup: function onkeyup(element) {
				this.element(element);
			},
			errorElement: "div",
			errorPlacement: function errorPlacement(error, element) {
				setupErrorPlacementValidation(error, element);
			},
			rules: {
				email: {
					hasReservedDomain: true,
					isValidEmail: true
				}
			},
			submitHandler: function submitHandler(form) {
				$newsletterForm.find(".fa-spinner").show();
				form.submit();
			}
		});
	}

	//used in MembershipCardLogin
	if ($('form#formprofile').length > 0) {
		var form = $('form#formprofile');
		form.validate({
			onfocusout: function onfocusout(element) {
				this.element(element);
			},
			errorPlacement: function errorPlacement(error, element, param) {
				error.insertAfter(element);
			}
		});
	}

	/*AVATAR functionality*/
	if ($('#upload-avatar').length > 0) {
		var $uploadCrop = $('#upload-avatar').croppie({
			enableExif: false,
			enableOrientation: true,
			enableZoom: true,
			enforceBoundary: true,
			mouseWheelZoom: true,
			showZoomer: true,
			viewport: {
				width: 240,
				height: 240,
				type: 'circle'
			},
			boundary: {
				width: 300,
				height: 300
			}
		});

		$('#upload').on('change', function (e) {
			var reader = new FileReader();
			$("p.subhead").remove();
			reader.onload = function (e) {
				if (e.loaded <= 5242880) {
					$("#size-error").hide();
					$uploadCrop.croppie('bind', {
						url: e.target.result
					}).then(function () {
						$("#avatar, #upload-button").hide();
						$("#actions-buttons").show();
						$('#upload-avatar').css('opacity', '1');
						$('#upload-avatar').siblings('h2.title').addClass('show-croppie');
					});
				} else {
					$("#size-error").show();
				}
			};
			reader.readAsDataURL(this.files[0]);
		});

		$('.rotate').on('click', function (ev) {
			$uploadCrop.croppie('rotate', 90);
		});
		$(".view_picture").click(function () {
			$uploadCrop.croppie('result', {
				type: 'canvas',
				size: 'viewport'
			}).then(function (resp) {
				popupResult({
					src: resp
				});
			});
		});

		$('#send').on('click', function (ev) {
			$uploadCrop.croppie('result', {
				type: 'base64',
				size: 'viewport',
				format: 'png'
			}).then(function (resp) {
				$.ajax({
					url: endpoint + '/api/Avatar/UploadAvatar',
					type: "POST",
					data: {
						"data": resp
					},
					success: function success(data) {
						var url = "";
						if (data.results == "OK") {
							url = window.location.href.split('?')[0] + '?upload=ok';
							window.location.href = url;
						} else if (data.results == "errorsize") {
							$("#size-error").show();
							$("#upload-button").show();
							$("#actions-buttons").hide();
							url = "";
						} else {
							$(".error-bar").addClass('show');
							url = "";
						}
					}
				});
			});
		});
	} //upload avatar

	if ($(".actions-invite").length > 0) {
		$(".actions-invite").on("click", function () {
			var action = $(this).data("action"),
			    id = $(this).data("id"),
			    modal = '.' + $(this).data("modal"),
			    accept = $(modal).find(".accept");
			$(modal).modal('show');
			accept.on("click", function () {
				var type = "POST",
				    url = action == "delete" ? '/api/InviteFamilyMember/DeleteFamilyMember' : '/api/InviteFamilyMember/ResendInvitationFamilyMember',
				    data = {
					memberid: id
				},
				    dataType = 'json',
				    request = ajaxRequest(type, url, data, dataType);
				request.promise().done(function () {
					if (request.responseJSON.result == "ok") {
						location.reload();
					} else {
						$(modal).modal('hide');
						$(".error-bar").addClass('show');
						$("body").scrollView();
					}
				}); //promise
			});
		});
	}

	if ($("#per-error").length > 0) {
		setTimeout(function () {
			$("#per-error").scrollView();
		}, 1000);
	}

	toggleDisabledInContinueBtn();
	$(".mandatory-radios input[type='radio']").on("click", function () {
		toggleDisabledInContinueBtn();
	});

	$("#continue-plans").on("click", function () {
		enrollButtonClick();
	});

	toggleContinueBtn();
	$(".c-099 .mandatory-radios input[type='radio']").on("click", function () {
		toggleContinueBtn();
	});
	// $(".btn-continue").on("click", function () {
	// 	if(!$('.form-plans').hasClass('hidden')){
	// 		$('.form-plans').addClass('hidden');
	// 		$('#first-title').addClass('hidden');
	// 		$('#second-title').removeClass('hidden');
	// 	}
	// 	else{
	// 		$('.form-plans').removeClass('hidden');
	// 		$('#first-title').removeClass('hidden');
	// 		$('#second-title').addClass('hidden');
	// 	}
	// });
	$("#continue-2").on("click", function () {
		enrollButtonClick();
	});

	//Testimonial form
	if ($('form#testimonials').length > 0) {
		setupAccentWhenValidForm("testimonials", 3);
		validateMessageCharCount();
		var $testimonialForm = $('form#testimonials');
		$testimonialForm.validate({
			onfocusout: function onfocusout(element) {
				this.element(element);
				setupAccentWhenValidForm("testimonials", 3);
			},
			onclick: function onclick(element) {
				this.element(element);
				setupAccentWhenValidForm("testimonials", 3);
			},
			rules: {
				enterTestimonial: {
					maxlength: 250
				}
			},
			errorElement: "div",
			errorPlacement: function errorPlacement(error, element) {
				setupErrorPlacementValidation(error, element);
			},
			submitHandler: function submitHandler(form, event) {
				if ($('.valid').length > 2) {
					event.preventDefault();
					$('button[type=submit]').find(".fa-spinner").show();
					$('button[type=submit]').addClass('disabled');
					$.ajax({
						dataType: 'json',
						type: 'POST',
						url: '/api/Testimonial/ExecuteTestimonial',
						success: function success(response) {
							if (response.result === "ok") {
								$('#profileModal').modal('show');
							} else {
								response.result === "error" ? $('.error-bar').addClass('show') : window.location.reload(true);
							}
							$('button[type=submit]').find(".fa-spinner").hide();
							$('button[type=submit]').removeClass('disabled');
						},
						error: function error() {
							$('.error-bar').addClass(' show');
						},
						data: {
							category: $('#testimonialCategory').val(),
							testimonial: $('#message').val()
						}
					});
				}
				return false;
			}
		});
	}
}); //function

function ajaxRequest(type, url, data, dataType) {
	return $.ajax({
		type: type,
		url: url,
		data: data,
		dataType: dataType
	});
}

function popupResult(result) {
	swal({
		title: 'Image Result',
		content: {
			element: "img",
			attributes: {
				src: result.src
			}
		},
		text: null,
		allowOutsideClick: true
	});
	setTimeout(function () {
		$('.sweet-alert').css('margin', function () {
			var top = -1 * ($(this).height() / 2),
			    left = -1 * ($(this).width() / 2);
			return top + 'px 0 0 ' + left + 'px';
		});
	}, 1);
}

/**
 * Change submit button color to blue when form is valid
 * @param {*} formName Form Name
 * @param {*} validInputsCount How many inputs should be validated to consider a valid form
 */
function setupAccentWhenValidForm(formName, validInputsCount) {
	var errorMessages = $(".floating-error-messages .error:visible").length;
	var validInputs = $("#" + formName + " input.valid, #" + formName + " select.valid, #" + formName + " textarea.valid").length;
	var submitBtn = $("#" + formName + " :submit");
	var submitMessage = $("#" + formName + " .floating-submit-messages");

	if (formName === "login") {
		submitBtn = $("#submitLogin");
	}

	if (errorMessages === 0 && validInputs == validInputsCount) {
		submitBtn.addClass("accent");
		submitMessage.show();
	} else {
		submitBtn.removeClass("accent");
		submitMessage.hide();
	}
}

/**
 * Validate Char Count for Contact us textarea * 
 */
function validateMessageCharCount() {
	var number = $('textarea#message').parents('.floating-section').find('.number');
	$('textarea#message').on("input", function () {
		var maxlength = $(this).data("rule-maxlength");
		var currentLength = $(this).val().length;

		var charLeft = maxlength - currentLength;
		if (currentLength <= maxlength) {
			number.text(charLeft);
		}
	});
}

/**
 * Setup helper for text area floating label
 */
function setupTextAreaLabelHelper() {
	$('.new-design.form textarea').on("focus", function (e) {
		$(this).parent(".fix-floating-label").addClass("fs");
	}).on("blur", function (e) {
		if (!$(this).hasClass("error")) {
			$(this).parent(".fix-floating-label").removeClass("fs");
		}
	});
}

/**
 * Fire info messages on focus for inputs
 */
function setupFormInfoMesages() {
	$(".new-design.form input, .new-design.form select, .new-design.form textarea").not("button").on("focus blur", function (e) {
		var $messages = $(this).closest(".floating-section").find(".floating-info-messages");
		if (e.type === "focus") {
			$messages.show();
		} else {
			if (e.relatedTarget && e.relatedTarget.type == "submit") {
				return;
			} else {
				$messages.hide();
			}
		}
	});
}

/**
 * Set Up the labels for errors when validating a form 
 * @param {*} error The error label to insert into the DOM.
 * @param {*} element The validated input, for relative positioning.
 */
function setupErrorPlacementValidation(error, element) {
	error.appendTo(element.closest(".floating-section").find(".floating-error-messages"));
	var serverError = element.closest(".floating-section").find(".floating-error-messages .server-error");

	if (serverError.length > 0) {
		serverError.remove();
	}
}

/**
 * Set up message to show the amount of payout you have
 */
function setupPayoutLeftMessage() {
	var payoutAvailable = parseInt($('#payoutAvailable').val());
	$("#TotalPercent").html(payoutAvailable);
	$('#payoutErr').show();

	$('#payoutPercentage').on("change keyup", function () {
		var payoutAvailable = parseInt($('#payoutAvailable').val());
		var currentPayout = parseInt($('#currentPayout').val());
		var newPayout = parseInt(this.value);
		var newTotal = payoutAvailable + newPayout - currentPayout;
		$("#TotalPercent").html(newTotal);
	});
}

/**
	Set action when closing modals
*/
function setupHiddingModalAction() {
	$(function () {
		$(".modal.fade.modalForms:not('#registrationModal,#forgotPasswordModal,#resetpasswordModal')").on('hidden.bs.modal', function () {
			var url = window.location.href;
			window.location.href = url;
		});
	});
}

$(window).on("load", function () {
	if ($('form#cta input[id="email"]').length > 0) {
		if (!$('form#cta input[id="email"]').val()) {
			$('form#cta a').addClass("disabled");
		}
	}
	$('form#cta input[id="email"]').on("input", function (e) {
		if (!$("form#cta").valid()) {
			$('form#cta a').addClass("disabled");
		} else {
			$('form#cta a').removeClass("disabled");
		}
	});
});

/**
	Action for the Enroll Button on Enroll Get Started Component
*/
function enrollButtonClick() {
	var contextidmulti = $("#contextidmulti").val();
	var form = $("#enroll-form").serialize();
	$.ajax({
		type: 'POST',
		url: "/api/ProductMultiOffer/ProductMultiOfferAction",
		async: false,
		data: {
			dataform: form,
			contextitemid: contextidmulti,
			unionId: 1
		},

		dataType: 'json',
		success: function success(data) {
			$('#enroll-form').find(".fa-spinner").show();
			$('a#continue-plans').addClass('disabled');
			if (data.result == "error") {
				if (!$(".error-bar").hasClass("show")) {
					$(".error-bar").addClass("show");
					$('#enroll-form').find(".fa-spinner").hide();
					$('a#continue-plans').removeClass('disabled');
				}
			} else if (data.result == "errorlogin") {
				window.location.href = data.urlredirect;
			} else if (data.result == "ok") {
				if ($(".error-bar").hasClass("show")) {
					$(".error-bar").removeClass("show");
				}
				$("#productId").val(data.product);
				$("#formpartner").submit();
				setTimeout(function () {
					window.location.href = data.urlreturn;
				}, 3000);
			}
		}
	});
}

function toggleDisabledInContinueBtn() {
	var $continueBtn = document.getElementById('continue-plans');
	if ($(".mandatory-radios input[type='radio']").length == 0) {
		$($continueBtn).removeClass("disabled");
	} else {
		var count = 0;
		var totalMandatoryRadioGroups = $(".mandatory-radios").length;
		$(".mandatory-radios").each(function (index, element) {
			var requiredCheckboxName = $(element).find('input[type="radio"]').attr("name");
			if ($("input[name='" + requiredCheckboxName + "']").is(':checked')) {
				count++;
			}
		});
		if (count == totalMandatoryRadioGroups) {
			$($continueBtn).removeClass("disabled");
		} else {
			$($continueBtn).addClass("disabled");
		}
	}
}

function toggleContinueBtn() {
	var $continueBtn = document.getElementById('continue-1');
	if ($(".mandatory-radios input[type='radio']").length == 0) {
		$($continueBtn).removeClass("disabled");
	} else {
		var count = 0;
		var totalMandatoryRadioGroups = $(".mandatory-radios").length;
		$(".mandatory-radios").each(function (index, element) {
			var requiredCheckboxName = $(element).find('input[type="radio"]').attr("name");
			if ($("input[name='" + requiredCheckboxName + "']").is(':checked')) {
				count++;
			}
		});
		if (count == totalMandatoryRadioGroups) {
			$($continueBtn).removeClass("disabled");
		} else {
			$($continueBtn).addClass("disabled");
		}
	}
}
//Funtions for Send Verification Email
$('.messageLink').on('click', function () {
	verificationEmail();
});

function verificationEmail() {
	$.ajax({
		url: '/api/EmailVerification/SendVerificationEmail',
		type: 'POST',
		dataType: 'json',
		beforeSend: function beforeSend() {
			$('a.messageLink').addClass('disabled');
			$('#update-profile').find(".fa-spinner.verification").show();
		},
		complete: function complete() {
			$('a.messageLink').removeClass('disabled');
			$('#update-profile').find(".fa-spinner.verification").hide();
		},
		success: function success(data, textStatus, xhr) {
			var parentElement = $('.messageLink').parent();
			if (data.Data) {
				var confirmationValue = parentElement.data("confirm");
				changeDisplayedMessage(confirmationValue, parentElement);
			} else {
				var errorinput = $('#importantMessagesProfileError').val();
				$(parentElement).text(errorinput);
			}
		},
		error: function error(xhr, textStatus, errorThrown) {
			var errorinput = $('#importantMessagesProfileError').val();
			var parentElement = $('.messageLink').parent();
			$(parentElement).text(errorinput);
		}
	});
}
function changeDisplayedMessage(value, id) {

	$(id).text(value);
	$(id).removeClass('alert-warning');
	$(id).addClass('alert-info');
}
//End funtions for Send Verification Email

/**
 * Validate if user comes from /pages/unmatched-member * 
 */
function isUnmachedMember() {
	var originurl = document.referrer;
	var search = "/pages/unmatched-member";
	var searchES = "/contactenos";
	var pathname = window.location.pathname;
	if (originurl.indexOf(search) > -1) {
		var element = $("#topic");
		if (pathname.indexOf(searchES) > -1) {
			element.val("El Sitio no me Reconoce como Miembro").change();
		} else element.val("My Website Account Does Not Recognize Me as a Member").change();
	}
}