"use strict";

$(function () {
    // Scroll To
    if ($(".scroll").length > 0) {
        $(".scroll").click(function (e) {
            e.preventDefault();
            var dest = $(this).attr('href');
            var distance = $("#middle-menu").hasClass("fixed") ? 100 : 100;
            $('html, body').animate({
                scrollTop: $(dest).offset().top - distance
            }, 1000);
        });
    }

    if ($(".scrollSmooth").length > 0) {
        $(".scrollSmooth").click(function (e) {
            e.preventDefault();
            $('html, body').animate({
                scrollTop: $(this.hash).offset().top
            }, 500);
        });
    }

    if ($(".representative .panel-cta").length > 0) {
        var back = '<div class="col-md-12"><a href="javascript:history.back()" title="title" class="form-nav"> <i aria-hidden="true" class="fa fa-chevron-left"> </i><span>BACK </span></a></div>';
        $(".representative .panel-cta").before(back);
    } //if

    setTimeout(function () {
        if ($(".addthis_inline_share_toolbox").html() == "" && $(".addthis_inline_share_toolbox").length > 0) {
            $(".attribution").hide();
        }
    }, 5000);

    if ('scrollRestoration' in history) {
        history.scrollRestoration = 'manual';
    }
});

$.fn.scrollView = function () {
    return this.each(function () {
        var scroll_amount = $(this).data("scroll") ? $(this).data("scroll") : 0;
        $('html, body').animate({
            scrollTop: $(this).offset().top - scroll_amount
        }, 1000);
    });
};

var page = findGetParameter('goto');
if (page != undefined) {
    $(document).ready(function () {
        $('html, body').animate({
            scrollTop: $('#' + page).offset().top
        }, 500);
    });
}

function findGetParameter(parameterName) {
    var result = null,
        tmp = [];
    location.search.substr(1).split("&").forEach(function (item) {
        tmp = item.split("=");
        if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
    });
    return result;
};

function hasQuestionMark(url) {
    if (url.indexOf("?") > -1) {
        return true;
    }
    return false;
}

function customScrollToByClass(elementToScroll) {
    if ($("." + elementToScroll).length > 0) {
        $('html, body').animate({
            scrollTop: $("." + elementToScroll).offset().top
        }, 1000);
    }
}

function fireTrackingOracleSP() {
    var productCode = $("#actionprocedurepar1").val();
    operationprocedureactioncta(productCode);
}

function getCookie(key) {
    var keyValue = document.cookie.match('(^|;) ?' + key + '=([^;]*)(;|$)');
    return keyValue ? keyValue[2] : null;
}

function showVideoLightbox(videoId) {
    $("#" + videoId).modal("show");
    $("#" + videoId).on('hidden.bs.modal', function (event) {
        var modal = $(this);
        var playerId = modal.find('.jwplayer').attr('id');
        if (playerId) {
            if (jwplayer(playerId).getState() == "playing" || jwplayer(playerId).getState() == "buffering") {
                jwplayer(playerId).play(false);
            }
        } else {
            var playerYouTube = $("#" + videoId + "-frameId");
            playerYouTube[0].contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
        }
        jwplayer(playerId).stop();
    });
}

//Session Lightbox functions

$(window).on("load", function () {
    var delayTime = parseInt($('#delayTime').text());
    var modalId = $('.sessionModal').attr('id');
    checkCookie(delayTime, modalId);
    $('#' + modalId).on('shown.bs.modal', function (e) {
        e.preventDefault();
        var modalId = $('.sessionModal').attr('id');
        var getSessionId = getCookie("SessionGroupId");
        if (getSessionId != "" && getSessionId != null) {
            if (!getSessionId.includes(modalId)) document.cookie = "SessionGroupId=" + getSessionId + ', ' + modalId;
        } else {
            document.cookie = "SessionGroupId=" + modalId;
        }
    });
});

function checkCookie(delay, id) {
    var getSessionId = getCookies("SessionGroupId");
    if (getSessionId != "" && getSessionId != null) {
        //getCookies("SessionGroupId");
        if (!getSessionId.includes(id)) showSessionModal(delay);
    } else {
        showSessionModal(delay);
    }
}

function showSessionModal(delay) {
    setTimeout(function () {
        $('.sessionModal').modal('show');
    }, delay);
}

$(document).on('touchstart, click', function (event) {
    // Get the modal element and content
    var modal = $('.sessionModal');
    var modalContent = modal.find('.modal-content');

    // Check if click is outside the modal content
    if (!modalContent.is(event.target) && !modalContent.has(event.target).length) {
        // Hide the modal
        modal.modal('hide');
    }
});

function getCookies(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}
//End Session Lightbox functions

//Body NAvigation

if ($('.c-097')) {
    var generateDropdownMarkup = function generateDropdownMarkup(container) {
        var $navWrapper = container.find(".nav-wrapper");
        var $navPills = container.find(".nav-pills");
        var firstTextLink = $navPills.find("li:first-child a").text();
        var $items = $navPills.find("li");
        var markup = "\n                        <div class=\"dropdown dropdown-pills hidden-md hidden-lg\">\n                        <button class=\"btn dropdown-toggle\" type=\"button\" id=\"dropdownMenuButton\" data-toggle=\"dropdown\" aria-haspopup=\"true\" aria-expanded=\"false\">\n                            " + firstTextLink + "\n                        </button>\n                        <div class=\"dropdown-menu\" aria-labelledby=\"dropdownMenuButton\"> \n                            " + generateDropdownLinksMarkup($items) + "\n                        </div>\n                        </div>\n                    ";
        $navWrapper.append(markup);
    };

    var generateDropdownLinksMarkup = function generateDropdownLinksMarkup(items) {
        var markup = "";
        items.each(function () {
            var textLink = $(this).find("a").text();
            markup += "<a class=\"dropdown-item\" href=\"#\">" + textLink + "</a>";
        });

        return markup;
    };

    var showDropdownHandler = function showDropdownHandler(e) {
        // works also
        //const $this = $(this);
        var $this = $(e.target);
        var $dropdownToggle = $this.find(".dropdown-toggle");
        var dropdownToggleText = $dropdownToggle.text().trim();
        var $dropdownMenuLinks = $this.find(".dropdown-menu a");
        var dNoneClass = "d-none";
        $dropdownMenuLinks.each(function () {
            var $this = $(this);
            if ($this.text() == dropdownToggleText) {
                $this.addClass(dNoneClass);
            } else {
                $this.removeClass(dNoneClass);
            }
        });
    };

    var clickHandler = function clickHandler(e) {
        e.preventDefault();
        var $this = $(this);
        var index = $this.index();
        var text = $this.text();
        $this.closest(".dropdown").find(".dropdown-toggle").text("" + text);
        $this.closest($tabsToDropdown).find(".nav-pills li:eq(" + index + ") a").tab("show");
    };

    var shownTabsHandler = function shownTabsHandler(e) {
        // works also
        //const $this = $(this);
        var $this = $(e.target);
        var index = $this.parent().index();
        var $parent = $this.closest($tabsToDropdown);
        var $targetDropdownLink = $parent.find(".dropdown-menu a").eq(index);
        var targetDropdownLinkText = $targetDropdownLink.text();
        $parent.find(".dropdown-toggle").text(targetDropdownLinkText);
    };

    var $tabsToDropdown = $(".tabs-to-dropdown");

    $tabsToDropdown.each(function () {
        var $this = $(this);
        var $pills = $this.find('a[data-toggle="pill"]');

        generateDropdownMarkup($this);

        var $dropdown = $this.find(".dropdown");
        var $dropdownLinks = $this.find(".dropdown-menu a");

        $dropdown.on("show.bs.dropdown", showDropdownHandler);
        $dropdownLinks.on("click", clickHandler);
        $pills.on("shown.bs.tab", shownTabsHandler);
    });
}
//End Body Navigation